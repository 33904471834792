<template>
  <HomePageBuilder :content="content" />
</template>

<script>
import HomePageBuilder from '~contemporaryTheme/pages/index'

export default {
  components: {
    HomePageBuilder,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
