<template>
  <div>
    <component
      :is="getPageBuilderWidgetByType(widget.type)"
      v-for="widget in (content.template || [])"
      :key="widget.id"
      :class="{ 'max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8' :  widget.type !== 'hero' }"
      :value="widget"
    />
  </div>
</template>

<script>
import usePageBuilderWidgets from '@/composables/usePageBuilderWidgets'

export default {
  setup() {
    const { getPageBuilderWidgetByType } = usePageBuilderWidgets()
    return {
      getPageBuilderWidgetByType,
    }
  },
  props:{
    content: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
